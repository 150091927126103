/* src/components/PopularDestinations.css */
.review-flex{
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: center;
    width: 250px;
    column-gap: 20px;
}
.review-slide{
 overflow: hidden;
 width: 100%;
 min-height: 250px;
}
.carousel-item{
    column-gap: 10px;
    align-items: top;
    /* width: 200px; */
  }
  .carousel-item-feedback{
    display: flex;
    column-gap: 10px;
    align-items: top;
    margin-right: 100px;
  }
  .popular-destinations {
    text-align: center;
    padding: 20px 0;
    margin: 0 auto;
    font-family: 'Manrope';
  }
  
  #subheaderr{
    color: #000;
  }
  
  .popular-destinations p {
    color: #666;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    margin-bottom: 30px;
  }
  
  .carousel-popular {
    display: flex;
    width: 100%;
    align-items: top;
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 0px 0px;
    column-gap: 20px;
    scrollbar-color: #8b0000 transparent;
  }
  
  .carousel-item {
    /* flex: 0 0 auto; */
    /* min-width: 150px; */
    margin: 0px;
    text-align: center;
  }
  
  .carousel-item img {
    /* width: 150px; */
    height: 200px;
    border-radius: 10px;
    object-fit: cover;
    margin-bottom: 10px;
  }
  .review-contact{
    
  
  }
  .review-contact h4{
    padding:0px ;
    margin:0px ;
  }
  .review-contact p{
    padding:0px ;
    margin:0px ;
  }
  /* Responsive Styles */
  @media (max-width: 600px) {
  
    
  }
  