.core-header {
    text-align: center;
}

.core-details {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    margin: 30px 0px;
}
.core-details div{
    background-color: #8b0000;
    padding: 20px;
    color: white;
    width: 350px;
    line-height: 2;
}
.core-details div:nth-child(even) {
    background-color: #a11a1a;
}
.ripple{
	position: relative; /*Position relative is required*/
	cursor: pointer;
	overflow:hidden;
}

.ripple:hover:before{
	animation: ripple 1s ease;
}

.ripple:before{
	content:"";
	position:absolute; top:0; left:0;
	width:100%; height:100%;
  background-color:rgba(255, 255, 255, 0.7);
  border-radius:50%;
	transform:scale(0);
}

@keyframes ripple{
	from{transform:scale(0); opacity:1;}
	to{transform:scale(3);opacity:0;}
}
