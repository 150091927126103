/* src/components/partners.css */
.partners{
    background-color: white;
}
.partners p{
    margin: 0px;
}
.partners h2{
    margin: 0px;
}
.pt-logo-slider {
    margin-top: 10px;
    width: 100%;
    max-width: 700px;
  }
  
  .pt-logo-container {
    display: flex;
    align-items: center;
    animation: scroll 15s linear infinite; 
  }
  
  .pt-logo {
    flex-shrink: 0;
    max-width: 100px;
    margin: 0 20px;
    transition: transform 0.3s;
  }
  
  .pt-logo:hover {
    transform: scale(1.1);
  }
  .partner-main{
    overflow-x: hidden;
    display: flex;
    justify-content: center;
  }
  @media (max-width: 768px) {
    .pt-logo-slider {
        margin-top: 10px;
        width: 100%;
        max-width: 380px;
      }
    .pt-logo-container {
      flex-wrap: nowrap;
      justify-content: center;
    }
  
    .pt-logo {
      margin: 10px;
    }
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-1 * var(--total-width) / 2));
    }
  }
  