.hm-slide {
    background-color: white;
    height: 550px;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%;
}
.hm-slide-con {
    position: relative;
    height: 520px;
    width: 100%;
}
/* Slide Logic */
.hm-slide-img {
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 520px;
    position: absolute;
    background-size: cover;
}
.hm-slide-fade {
    background-color: black;
    opacity: 0.5;
    height: 520px;
    width: 100%;
    position: absolute;
}
.hm-slide-content {
    width: 100%;
    height: 490px;
    position: absolute;
    color: white;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 30px;
}
.hm-slide-img-1 {
    background-image: url("../assets/images/plant.png");
}
.hm-slide-img-2 {
    background-image: url("../assets/images/drilling.png");
}
.hm-slide-img-3 {
    background-image: url("../assets/images/cement.webp");
}
.hm-slide-img-4 {
    background-image: url("../assets/images/road.jpg");
}
.hm-slide-content-title {
    font-size: 40px;
    font-weight: 900;
    margin: 0px;
}
.hm-slide-content-sub {
    font-size: 20px;
    max-width: 700px;
    width: 90%;
    line-height: 2;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.hm-slide-content-but {
    color: #8b0000;
    font-size: 15px;
    background-color: white;
    border-radius: 5px;
    padding: 10px 30px;
    transition: all 800ms;
}
.hm-slide-content-but:hover {
    background-color: #8b0000;
    color: white;
}
.hm-abt-contain {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
    padding: 30px 20px 30px 20px;
    background-color: whitesmoke;
}
.hm-abt-content {
    background-color: #8b0000;
    border-radius: 5px;
    color: white;
    padding: 20px;
    line-height: 2;
    text-align: center;
}
.hm-abt-img {
    width: 400px;
    border-radius: 10px;
}
.hm-service-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.hm-service-body {
    border: #8b0000 1px solid;
    padding: 0px 0px 10px 0px;
    color: black;
    display: flex;
    margin: 10px;
    width: 350px;
    height: 200px;
    column-gap: 30px;
}
.hm-service-icon {
    min-width: 80px;
    height: 80px;
    border-right: 1px #8b0000 solid;
    border-bottom: 1px #8b0000 solid;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 1s;
}
.hm-service-icon svg {
    fill: #8b0000;
}
.hm-service-body:hover .hm-service-icon {
    background-color: #8b0000;
}
.hm-service-body:hover svg {
    fill: #ffffff;
}
.hm-service-body p {
    font-size: 13px;
    margin: 0px;
    line-height: 2;
}
.hm-service-body h3 {
    margin: 15px 0px 5px 0px;
}
.home-projects {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    background-color: #284582;
    column-gap: 20px;
    row-gap: 10px;
    padding: 50px 20px;
}
.project-title {
    font-size: 30px;
    color: white;
    font-weight: bold;
    width: 45%;
}
.project-scope {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 45%;
    color: white;
    justify-content: center;
    margin-top: 10px;
}
.project-scope-title {
    font-size: 40px;
    font-weight: bold;
    margin: 0px;
    line-height: 1;
}
.project-scope-subcontent {
    font-size: 15px;
    margin: 10px 0px 20px 0px;
    line-height: 1;
    font-weight: bold;
}

.pm-service-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    gap: 40px;
    padding: 20px;
}
.pm-service-body {
    /* border: #8b0000 1px solid; */
    color: black;
    display: flex;
    width: 350px;
    height: 300px;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.pm-service-inner{
    margin: 20px;
    background-color: #8b0000;
    color: white;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 0px;
    height: 0px;
    transition: all 1s;
}
.pm-service-inner p {
    font-size: 13px;
    margin: 0px 5px;
    line-height: 2;
    display: none;
}
.pm-service-inner h3 {
    display: none;
    margin: 15px 0px 5px 0px;
}
.pm-inner-bg-1{
    background-image: url("../assets/images/project_1.jpg");
}
.pm-inner-bg-2{
    background-image: url("../assets/images/project_2.jpeg");
}
.pm-inner-bg-3{
    background-image: url("../assets/images/project_3.jpeg");
}
.pm-inner-bg-4{
    background-image: url("../assets/images/project_4.webp");
}
.pm-service-body:hover .pm-service-inner{
    width: 320px;
    height: 270px;
}
.pm-service-inner:hover p{
    display: block;
}
.pm-service-inner:hover h3{
    display: block;
}
.pm-service-body:hover p{
    width: 310px;
}
.pm-service-body:hover h3{
    width: 310px;
}

@media (max-width: 500px) {
    .project-title {
        font-size: 30px;
        color: white;
        font-weight: bold;
        width: 100%;
    }

    .project-scope {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        color: white;
        justify-content: center;
    }
}
