.hd-space-between {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    padding: 5px;
    background-color: black;
    color: white;
}
.hd-mid-space {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    padding: 10px;
    row-gap: 10px;
}
.hd-contact-div {
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid #204fa4 1px;
    color: #204fa4;
    border-radius: 5px;
    background-color: white;
    transition: background-color 600ms;
    font-size: 20px;
    padding: 15px;
    margin: 10px;
}
.hd-contact-div:hover {
    color: white;
    background-color: #204fa4;
}
.logo {
    height: 80px;
}
.hd-mid-space p {
    font-size: 11px;
    margin: 5px;
}
.hd-contact {
    color: black;
    text-decoration: none;
    cursor: pointer;
}
.hd-contact:hover {
    color: #204fa4;
}
.hd-menu {
    display: flex;
    column-gap: 30px;
    justify-content: center;
    align-items: center;
    color: white;
    margin: 20px 10px 20px 30px;
}
.hd-menu p {
    margin: 5px 0px;
}
.hd-menu-con {
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 0px;
}
.hd-menu-line {
    background-color: white;
    margin: 0px;
    height: 2px;
    padding: 0px;
    width: 0px;
    transition: width 1s;
}

.hd-menu-con:hover {
    font-weight: bold;
    cursor: pointer;
    align-items: stretch;
}
.hd-menu-con:hover .hd-menu-line {
    width: 50px;
}
.hd-menu-main {
    background-color: black;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    color: white;
    width: 80%;
}
.hd-menu-main-mobile {
    background-color: black;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    color: white;
    width: 95%;
    height: 50px;
}
.hamburger {
    align-self: center;
    padding-right: 10px;
}
.hd-menu-bar {
    display: flex;
    justify-content: center;
    margin: 10px 10px 0px 10px;
}
.hd-menu-bar-mobile {
    display: flex;
    justify-content: center;
    margin: 10px 10px 0px 10px;
}
.hd-menu-mobile {
    display: flex;
    flex-direction: column;
    column-gap: 30px;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: #8b0000;
    height: 250px;
    transition: height 1s;
}
.hd-menu-mobile-hide {
    height: 0px;
}
.hd-menu-mobile p {
    margin: 5px 0px;
}
.hd-quote {
    background-color: #204fa4;
    color: white;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    cursor: pointer;
    transition: background-color 1s;
}
.hd-quote:hover {
    background-color: #182d58;
}
.hd-quote a{
    color: white;
    text-decoration: none;
    font-weight: bold;
}
