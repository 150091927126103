.abt-top-level{
    background-color: black;
    color: white;
    padding: 50px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
}

.about-background{
    background-image: url("../assets/images/about.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding:20px;
    min-height: 250px;
    display: flex;
    align-items: stretch;
}
.about-background-content{ 
    background-color: white;
    opacity: 0.9;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    text-align: center;
    line-height: 2;
}


.abt-abt-contain {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
    padding: 30px 20px 30px 20px;
    background-color: whitesmoke;
}
.abt-service-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.abt-service-body {
    border-radius: 5px;
    background-color: #8b0000;
    padding: 0px 10px 10px 0px;
    color: black;
    display: flex;
    margin: 10px;
    width: 350px;
    height: 300px;
    column-gap: 20px;
}
.abt-service-icon {
    min-width: 80px;
    height: 80px;
    border-radius: 5px 0px 5px 0px;
    border-right: 1px #a46666 solid;
    border-bottom: 1px #a46666 solid;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 1s;
}
.abt-service-icon svg {
    fill: white;
}
.abt-service-body:hover .abt-service-icon {
    background-color: white;
}
.abt-service-body:hover svg {
    fill: #8b0000;
}
.abt-service-body p {
    font-size: 13px;
    margin: 0px;
    line-height: 2;
    color: white;
}
.abt-service-body h3 {
    margin: 15px 0px 5px 0px;
    color: white;
}