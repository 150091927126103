body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    /* background-color: #f5f5f5; */
  }
  .contact-grid{
    display: grid;
    grid-template-columns: auto auto;
    gap: 30px;
  }
  
  .contact {
    margin: 0 auto;
    padding: 50px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-image: url('../assets/images/bid.jpg');
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .contact-budget{
    width: 95% !important;
  }
  .map-section {
    
  }
  
  .contact-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #8b0000;
    padding: 30px 30px;
    max-width:600px;
    color: white;
  }
  .contact-section input {
    width: 98%;
    margin: 10px 0px;
    height: 40px;
  }
  .contact-section textarea {
    width: 98%;
    margin: 10px 0px;
    height: 133px;
  }
  .contact-section select {
    width: 100%;
    margin: 10px 0px 10px 0px;
    height: 60px;
  }
  .get-in-touch {
    margin-bottom: 0px;
  }
  
  .get-in-touch form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .get-in-touch input,
  .get-in-touch select,
  .get-in-touch button {
    padding: 10px;
    border-radius: 5px;
    border: none;
  }
  
  .get-in-touch button {
    width: 100%;
    background-color: white;
    color: #1c4753;
    cursor: pointer;
    font-weight: bold;
  }
  
  #service {
    color: gray;
    margin-left: 0px;
  }

  .map-img{

  }
  @media (max-width: 768px) {
    .contact {
      padding: 30px 0px;
    }
  
    .contact-section {
      margin: 0px;
    }
  
    .contact-section input {
      width: 95%;
      margin: 10px 0px;
      height: 40px;
    }
  
    .contact-section textarea {
      width: 95%;
      margin: 0px;
      height: 150px;
    }
  }
  