.footer-bg {
    background-color: black;
    color: white;
    justify-content: center;
    align-items: flex-start;
    display: flex;
    flex-flow: row wrap;
    column-gap: 40px;
    padding: 20px;
}
.footer-copy {
    background-color: #8b0000;
    color: white;
    justify-content: center;
    align-items: flex-start;
    display: flex;
    flex-flow: row wrap;
    column-gap: 40px;
    padding: 10px;
}
.footer-col{
    width: 330px;
}
.footer-title{
    font-weight: 700;
    font-size: 20px;
}
.footer-border{
    height: 3px;
    width: 50px;
    background-color: #8b0000;
    margin-bottom: 20px;
}
.footer-sub{
    line-height: 2;
    margin: 3px 0px 0px 0px;
}
.footer-input{
    height: 50px;
    width: 350px;
    margin: 10px 0px;
    border: none;
    background-color: white;
}
.footer-but{
    background-color: #8b0000;
    color: white;
    font-weight: 700;
    margin-top: 10px;
    width: 200px;
    height: 50px;
    border: none;
}
.ft-contact {
    color: white;
    text-decoration: none;
    cursor: pointer;
}
.ft-contact:hover {
    color: grey;
}